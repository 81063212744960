#app {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
}

#content { 
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

#links {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

#projects {
  display: flex;
  justify-content: center;
}

h1, h2 {
  color: white;
  font-family: "Roboto";
  font-weight: 100;
  text-align: center;
}

h4 {
  margin-right: 1em
}